import { useEffect } from "react";
import Cookies from "js-cookie";

/**
 * Initializes Pendo once the window's "load" event is triggered.
 * This makes pendo less likely to fail since its library *should* be loaded
 * before initialize() and getVisitorId() are called.
 */
const usePendo = () => {
  function initializePendo() {
    pendo &&
      pendo.initialize({
        // disable Pendo's in-app messaging if a cookie with the key PENDO_DISABLE_GUIDES exists.
        // we currently set this cookie in cypress/support/index.js as Pendo's overlay can cover elements we are testing.
        // https://support.pendo.io/hc/en-us/articles/360031862272-Installation-for-Single-Page-Frameworks
        disableGuides: Boolean(Cookies.get("PENDO_DISABLE_GUIDES")),
        visitor: {
          // For Intercom to initiate w/o an auth'd DADO user
          anonymous_id: pendo.getVisitorId(),
        },
      });
  }

  useEffect(() => {
    window.addEventListener("load", initializePendo);

    return () => window.removeEventListener("load", initializePendo);
  }, []);
};

export default usePendo;
